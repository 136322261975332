export type tArrayExpression = 'ArrayExpression';
export type tArrayPattern = 'ArrayPattern';
export type tArrowFunctionExpression = 'ArrowFunctionExpression';
export type tAssignmentExpression = 'AssignmentExpression';
export type tAssignmentPattern = 'AssignmentPattern';
export type tAwaitExpression = 'AwaitExpression';
export type tBinaryExpression = 'BinaryExpression';
export type tBlockStatement = 'BlockStatement';
export type tBreakStatement = 'BreakStatement';
export type tCallExpression = 'CallExpression';
export type tCatchClause = 'CatchClause';
export type tChainExpression = 'ChainExpression';
export type tClassBody = 'ClassBody';
export type tClassDeclaration = 'ClassDeclaration';
export type tClassExpression = 'ClassExpression';
export type tConditionalExpression = 'ConditionalExpression';
export type tContinueStatement = 'ContinueStatement';
export type tDoWhileStatement = 'DoWhileStatement';
export type tEmptyStatement = 'EmptyStatement';
export type tExportAllDeclaration = 'ExportAllDeclaration';
export type tExportDefaultDeclaration = 'ExportDefaultDeclaration';
export type tExportNamedDeclaration = 'ExportNamedDeclaration';
export type tExportSpecifier = 'ExportSpecifier';
export type tExpressionStatement = 'ExpressionStatement';
export type tForStatement = 'ForStatement';
export type tForInStatement = 'ForInStatement';
export type tForOfStatement = 'ForOfStatement';
export type tFunctionDeclaration = 'FunctionDeclaration';
export type tFunctionExpression = 'FunctionExpression';
export type tIdentifier = 'Identifier';
export type tIfStatement = 'IfStatement';
export type tImport = 'Import';
export type tImportDeclaration = 'ImportDeclaration';
export type tImportExpression = 'ImportExpression';
export type tImportDefaultSpecifier = 'ImportDefaultSpecifier';
export type tImportNamespaceSpecifier = 'ImportNamespaceSpecifier';
export type tImportSpecifier = 'ImportSpecifier';
export type tLabeledStatement = 'LabeledStatement';
export type tLiteral = 'Literal';
export type tLogicalExpression = 'LogicalExpression';
export type tMemberExpression = 'MemberExpression';
export type tMetaProperty = 'MetaProperty';
export type tMethodDefinition = 'MethodDefinition';
export type tNewExpression = 'NewExpression';
export type tObjectExpression = 'ObjectExpression';
export type tObjectPattern = 'ObjectPattern';
export type tPrivateIdentifier = 'PrivateIdentifier';
export type tProgram = 'Program';
export type tProperty = 'Property';
export type tPropertyDefinition = 'PropertyDefinition';
export type tRestElement = 'RestElement';
export type tReturnStatement = 'ReturnStatement';
export type tSequenceExpression = 'SequenceExpression';
export type tSpreadElement = 'SpreadElement';
export type tStaticBlock = 'StaticBlock';
export type tSuper = 'Super';
export type tSwitchCase = 'SwitchCase';
export type tSwitchStatement = 'SwitchStatement';
export type tTaggedTemplateExpression = 'TaggedTemplateExpression';
export type tTemplateElement = 'TemplateElement';
export type tTemplateLiteral = 'TemplateLiteral';
export type tThisExpression = 'ThisExpression';
export type tThrowStatement = 'ThrowStatement';
export type tTryStatement = 'TryStatement';
export type tUnaryExpression = 'UnaryExpression';
export type tUpdateExpression = 'UpdateExpression';
export type tVariableDeclarator = 'VariableDeclarator';
export type tVariableDeclaration = 'VariableDeclaration';
export type tWhileStatement = 'WhileStatement';
export type tYieldExpression = 'YieldExpression';

export const ArrayExpression: tArrayExpression = 'ArrayExpression';
export const ArrayPattern: tArrayPattern = 'ArrayPattern';
export const ArrowFunctionExpression: tArrowFunctionExpression = 'ArrowFunctionExpression';
export const AssignmentExpression: tAssignmentExpression = 'AssignmentExpression';
export const AssignmentPattern: tAssignmentPattern = 'AssignmentPattern';
export const AwaitExpression: tAwaitExpression = 'AwaitExpression';
export const BinaryExpression: tBinaryExpression = 'BinaryExpression';
export const BlockStatement: tBlockStatement = 'BlockStatement';
export const BreakStatement: tBreakStatement = 'BreakStatement';
export const CallExpression: tCallExpression = 'CallExpression';
export const CatchClause: tCatchClause = 'CatchClause';
export const ChainExpression: tChainExpression = 'ChainExpression';
export const ClassBody: tClassBody = 'ClassBody';
export const ClassDeclaration: tClassDeclaration = 'ClassDeclaration';
export const ClassExpression: tClassExpression = 'ClassExpression';
export const ConditionalExpression: tConditionalExpression = 'ConditionalExpression';
export const ContinueStatement: tContinueStatement = 'ContinueStatement';
export const DoWhileStatement: tDoWhileStatement = 'DoWhileStatement';
export const EmptyStatement: tEmptyStatement = 'EmptyStatement';
export const ExportAllDeclaration: tExportAllDeclaration = 'ExportAllDeclaration';
export const ExportDefaultDeclaration: tExportDefaultDeclaration = 'ExportDefaultDeclaration';
export const ExportNamedDeclaration: tExportNamedDeclaration = 'ExportNamedDeclaration';
export const ExportSpecifier: tExportSpecifier = 'ExportSpecifier';
export const ExpressionStatement: tExpressionStatement = 'ExpressionStatement';
export const ForStatement: tForStatement = 'ForStatement';
export const ForInStatement: tForInStatement = 'ForInStatement';
export const ForOfStatement: tForOfStatement = 'ForOfStatement';
export const FunctionDeclaration: tFunctionDeclaration = 'FunctionDeclaration';
export const FunctionExpression: tFunctionExpression = 'FunctionExpression';
export const Identifier: tIdentifier = 'Identifier';
export const IfStatement: tIfStatement = 'IfStatement';
export const Import: tImport = 'Import';
export const ImportDeclaration: tImportDeclaration = 'ImportDeclaration';
export const ImportExpression: tImportExpression = 'ImportExpression';
export const ImportDefaultSpecifier: tImportDefaultSpecifier = 'ImportDefaultSpecifier';
export const ImportNamespaceSpecifier: tImportNamespaceSpecifier = 'ImportNamespaceSpecifier';
export const ImportSpecifier: tImportSpecifier = 'ImportSpecifier';
export const LabeledStatement: tLabeledStatement = 'LabeledStatement';
export const Literal: tLiteral = 'Literal';
export const LogicalExpression: tLogicalExpression = 'LogicalExpression';
export const MemberExpression: tMemberExpression = 'MemberExpression';
export const MetaProperty: tMetaProperty = 'MetaProperty';
export const MethodDefinition: tMethodDefinition = 'MethodDefinition';
export const NewExpression: tNewExpression = 'NewExpression';
export const ObjectExpression: tObjectExpression = 'ObjectExpression';
export const ObjectPattern: tObjectPattern = 'ObjectPattern';
export const PrivateIdentifier: tPrivateIdentifier = 'PrivateIdentifier';
export const Program: tProgram = 'Program';
export const Property: tProperty = 'Property';
export const PropertyDefinition: tPropertyDefinition = 'PropertyDefinition';
export const RestElement: tRestElement = 'RestElement';
export const ReturnStatement: tReturnStatement = 'ReturnStatement';
export const SequenceExpression: tSequenceExpression = 'SequenceExpression';
export const SpreadElement: tSpreadElement = 'SpreadElement';
export const StaticBlock: tStaticBlock = 'StaticBlock';
export const Super: tSuper = 'Super';
export const SwitchCase: tSwitchCase = 'SwitchCase';
export const SwitchStatement: tSwitchStatement = 'SwitchStatement';
export const TaggedTemplateExpression: tTaggedTemplateExpression = 'TaggedTemplateExpression';
export const TemplateElement: tTemplateElement = 'TemplateElement';
export const TemplateLiteral: tTemplateLiteral = 'TemplateLiteral';
export const ThisExpression: tThisExpression = 'ThisExpression';
export const ThrowStatement: tThrowStatement = 'ThrowStatement';
export const TryStatement: tTryStatement = 'TryStatement';
export const UnaryExpression: tUnaryExpression = 'UnaryExpression';
export const UpdateExpression: tUpdateExpression = 'UpdateExpression';
export const VariableDeclarator: tVariableDeclarator = 'VariableDeclarator';
export const VariableDeclaration: tVariableDeclaration = 'VariableDeclaration';
export const WhileStatement: tWhileStatement = 'WhileStatement';
export const YieldExpression: tYieldExpression = 'YieldExpression';
